import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cardContainer", "cardFront", "cardBack", "prevButton", "nextButton", "counter", "currentFlashCard"]
  static values = { cards: Array }

  connect() {
    this.currentIndex = 0
    this.isShowingQuestion = true
    this.updateCard()
    this.updateCounter()
  }

  previousCard() {
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.isShowingQuestion = true
      this.currentFlashCardTarget.classList.remove('flipped')
      this.updateCard()
      this.updateCounter()
    }
  }

  nextCard() {
    console.log("Next card")
    if (this.currentIndex < this.cardsValue.length - 1) {
      this.currentIndex++
      this.isShowingQuestion = true
      this.currentFlashCardTarget.classList.remove('flipped')
      this.updateCard()
      this.updateCounter()
    }
  }

  flipCard(event) {
    const clickedElement = event.target
    const isSpeechButton = clickedElement.closest('[data-action="click->speech#speak"]')
    if (isSpeechButton) { return }
    this.element.querySelector('.flash-card').classList.toggle('flipped')
  }


  shuffleCards() {
    const shuffled = [...this.cardsValue].sort(() => Math.random() - 0.5)
    this.cardsValue = shuffled
    this.currentIndex = 0
    this.showQuestion()
    this.updateCard()
    this.updateCounter()
  }

  showQuestion() {
    if (!this.isShowingQuestion) {
      this.cardContainerTarget.classList.remove('flipped')
      this.isShowingQuestion = true
    }
  }

  updateCard() {
    const card = this.cardsValue[this.currentIndex]
    if (!card) {
      console.error("Card not found at index", this.currentIndex)
      return
    }

    const questionElement = `
      <div class="" data-controller="speech" data-speech-text-value="${card.question}">
        <span>${card.question}</span>
        <span
          data-action="click->speech#speak"
          data-speech-target="button"
          class="cursor-pointer flex items-center justify-center w-full h-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" version="1.0"  width="25" height="25" viewBox="0 0 75 75">
            <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" style="stroke-width:5;stroke-linejoin:round;fill:#111;"/>
            <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" style="fill:none;stroke-width:5;stroke-linecap:round"/>
          </svg>
        </span>
      </div>
    `

    const answerElement = `
      <div class="" data-controller="speech" data-speech-text-value="${card.answer}">
        <span>${card.answer}</span>
        <span
          data-action="click->speech#speak"
          data-speech-target="button"
          class="cursor-pointer flex items-center justify-center w-full h-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" version="1.0"  width="25" height="25" viewBox="0 0 75 75">
            <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" style="stroke-width:5;stroke-linejoin:round;fill:#111;"/>
            <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" style="fill:none;stroke-width:5;stroke-linecap:round"/>
          </svg>
        </span>
      </div>
    `

    this.cardFrontTarget.innerHTML = questionElement
    this.cardBackTarget.innerHTML = answerElement

    this.prevButtonTarget.disabled = this.currentIndex === 0
    this.nextButtonTarget.disabled = this.currentIndex === this.cardsValue.length - 1
  }

  updateCounter() {
    this.counterTargets.forEach(counter =>
      counter.textContent = `${this.currentIndex + 1}/${this.cardsValue.length}`
    )
  }
}
