import { Controller } from "@hotwired/stimulus"
import getJsConfetti from '../../shared/confetti'

export default class extends Controller {
  static targets = ["question", "option"]
  static values = {
    questions: Array
  }

  connect() {
    this.answers = new Array(this.questionsValue.length).fill(null)
  }

  checkAnswer(event) {
    const questionIndex = event.target.closest('[data-contents--multiple-choices-target="question"]').dataset.questionIndex
    const selectedAnswer = event.target.value
    this.answers[questionIndex] = selectedAnswer

    this.updateQuestionStyle(questionIndex, selectedAnswer)
    this.checkAllAnswers()
  }

  updateQuestionStyle(questionIndex, selectedAnswer) {
    const questionElement = this.questionTargets[questionIndex]
    const correctAnswer = this.questionsValue[questionIndex].answers[this.questionsValue[questionIndex].correct_answer]

    questionElement.classList.remove('bg-green-100', 'bg-red-100', 'dark:bg-green-600', 'dark:bg-red-600')
    if (selectedAnswer === correctAnswer) {
      questionElement.classList.add('bg-green-100', 'dark:bg-green-600')
    } else {
      questionElement.classList.add('bg-red-100', 'dark:bg-red-600')
    }
  }

  checkAllAnswers() {
    const allCorrect = this.answers.every((answer, index) => {
      const correctAnswer = this.questionsValue[index].answers[this.questionsValue[index].correct_answer]
      return answer === correctAnswer
    })

    if (allCorrect) {
      this.celebrate()
    }
  }

  celebrate() {
    const jsConfetti = getJsConfetti()
    jsConfetti.addConfetti()
  }
}
