import JSConfetti from 'js-confetti'

let jsConfettiInstance = null

function getJsConfetti() {
  if (!jsConfettiInstance) {
    jsConfettiInstance = new JSConfetti()
  }
  return jsConfettiInstance
}

document.addEventListener("turbo:render", () => {
  // Reset the instance after each Turbo Stream update
  jsConfettiInstance = null
})

export default getJsConfetti