import { Controller } from "@hotwired/stimulus"
import getJsConfetti from '../../shared/confetti'

export default class extends Controller {
  static targets = ["categoryContainer", "optionsContainer", "feedback", "submitButton", "resetButton", "rotationMessage"]
  static values = {
    categorizations: Object
  }

  connect() {
    this.initializeGame()
    this.scrollSpeed = 5 // Adjust this value to change scroll speed
    this.scrollThreshold = 50 // Adjust this value to change how close to the edge triggers scrolling
    this.isScrolling = false
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.checkOrientation)
  }

  checkOrientation() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isLandscape = width > height;
    const isMobile = width < 768;

    if (isMobile && !isLandscape) {
      this.rotationMessageTarget.classList.remove('hidden');
      this.element.classList.add('pointer-events-none');
    } else {
      this.rotationMessageTarget.classList.add('hidden');
      this.element.classList.remove('pointer-events-none');
    }
  }

  initializeGame() {
    const { categories } = this.categorizationsValue
    this.categories = categories
    this.allOptions = this.categories.flatMap(category => category.options)

    this.renderCategories()
    this.renderOptions()
    this.setupDragAndDrop()
  }

  renderCategories() {
    this.categoryContainerTarget.innerHTML = this.categories.map(category => `
      <div class="category p-4 bg-white dark:bg-gray-700 rounded-lg dark:shadow-none shadow-md border-b-2 border-gray-200" data-category="${category.name}">
        <h3 class="text-lg font-semibold mb-2 dark:text-white">${category.name}</h3>
        <div class="category-items min-h-[100px] border-2 border-dashed border-gray-300 p-2 rounded" data-droppable="true"></div>
      </div>
    `).join('')
  }

  renderOptions() {
    this.optionsContainerTarget.innerHTML = this.shuffleArray([...this.allOptions]).map(option => `
      <div class="option bg-blue-600 text-white p-2 m-1 rounded cursor-move flex items-center justify-between" draggable="true" data-option="${option}" data-controller="speech" data-speech-text-value="${option}">
        <span>${option}</span>
        <span
          data-action="click->speech#speak"
          data-speech-target="button"
          class="cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" version="1.0"  width="25" height="25" viewBox="0 0 75 75">
            <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" style="stroke-width:5;stroke-linejoin:round;fill:#111;"/>
            <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" style="fill:none;stroke-width:5;stroke-linecap:round"/>
          </svg>
        </span>
      </div>
    `).join('')
  }

  setupDragAndDrop() {
    this.element.addEventListener('dragstart', this.dragStart.bind(this))
    this.element.addEventListener('dragover', this.dragOver.bind(this))
    this.element.addEventListener('drop', this.drop.bind(this))

    // Touch events for mobile support
    this.element.addEventListener('touchstart', this.touchStart.bind(this), { passive: false })
    this.element.addEventListener('touchmove', this.touchMove.bind(this), { passive: false })
    this.element.addEventListener('touchend', this.touchEnd.bind(this))
  }

  dragStart(e) {
    if (e.target.classList.contains('option')) {
      e.dataTransfer.setData('text/plain', e.target.dataset.option)
      console.log("Drag started:", e.target.dataset.option)
    }
  }

  dragOver(e) {
    if (e.target.closest('[data-droppable="true"]')) {
      e.preventDefault()
    }
  }

  drop(e) {
    const dropTarget = e.target.closest('[data-droppable="true"]')
    if (dropTarget) {
      e.preventDefault()
      const option = e.dataTransfer.getData('text/plain')
      const category = dropTarget.closest('.category').dataset.category
      console.log("Drop attempt:", option, "into", category)
      this.addOptionToCategory(option, category)
    }
  }

  addOptionToCategory(option, category) {
    const categoryElement = this.element.querySelector(`.category[data-category="${category}"] .category-items`)
    const optionElement = this.element.querySelector(`.option[data-option="${option}"]`)

    if (optionElement && categoryElement) {
      categoryElement.appendChild(optionElement)
      console.log("Option added to category:", option, "to", category)
    } else {
      console.log("Failed to add option to category:", option, category)
      if (!optionElement) console.log("Option element not found")
      if (!categoryElement) console.log("Category element not found")
    }
  }

  touchStart(e) {
    if (e.target.classList.contains('option')) {
      e.preventDefault()
      this.touchDraggedElement = e.target
      const touch = e.touches[0]
      this.touchOffsetX = touch.clientX - e.target.getBoundingClientRect().left
      this.touchOffsetY = touch.clientY - e.target.getBoundingClientRect().top
      this.touchDraggedElement.classList.add('dragging')
      console.log("Touch start:", this.touchDraggedElement.dataset.option)
    }
  }

  touchMove(e) {
    if (this.touchDraggedElement) {
      e.preventDefault()
      const touch = e.touches[0]
      this.touchDraggedElement.style.position = 'fixed'
      this.touchDraggedElement.style.left = `${touch.clientX - this.touchOffsetX}px`
      this.touchDraggedElement.style.top = `${touch.clientY - this.touchOffsetY}px`
      this.touchDraggedElement.style.zIndex = '1000'

      this.handleAutoScroll(touch.clientY)
    }
  }

  touchEnd(e) {
    if (this.touchDraggedElement) {
      e.preventDefault()
      this.stopScrolling()
      const touch = e.changedTouches[0]

      // Hide the dragged element temporarily
      this.touchDraggedElement.style.display = 'none'

      // Check for droppable areas around the touch point
      const dropTargets = document.elementsFromPoint(touch.clientX, touch.clientY)
      console.log("Potential drop targets:", dropTargets)

      // Show the dragged element again
      this.touchDraggedElement.style.display = ''

      const droppableArea = dropTargets.find(el => el.closest('[data-droppable="true"]'))

      if (droppableArea) {
        const option = this.touchDraggedElement.dataset.option
        const category = droppableArea.closest('.category').dataset.category
        console.log("Attempting to add:", option, "to", category)
        this.addOptionToCategory(option, category)
      } else {
        console.log("No droppable area found")
      }

      this.resetTouchDraggedElement()
    }
  }

  handleAutoScroll(touchY) {
    const windowHeight = window.innerHeight
    const topThreshold = this.scrollThreshold
    const bottomThreshold = windowHeight - this.scrollThreshold

    if (touchY < topThreshold) {
      this.startScrolling(-this.scrollSpeed)
    } else if (touchY > bottomThreshold) {
      this.startScrolling(this.scrollSpeed)
    } else {
      this.stopScrolling()
    }
  }

  startScrolling(speed) {
    if (!this.isScrolling) {
      this.isScrolling = true
      this.scroll(speed)
    }
  }

  scroll(speed) {
    if (this.isScrolling) {
      window.scrollBy(0, speed)
      requestAnimationFrame(() => this.scroll(speed))
    }
  }

  stopScrolling() {
    this.isScrolling = false
  }

  resetTouchDraggedElement() {
    if (this.touchDraggedElement) {
      this.touchDraggedElement.style.position = ''
      this.touchDraggedElement.style.left = ''
      this.touchDraggedElement.style.top = ''
      this.touchDraggedElement.style.zIndex = ''
      this.touchDraggedElement.style.display = ''
      this.touchDraggedElement.classList.remove('dragging')
      this.touchDraggedElement = null
    }
    this.stopScrolling()
  }

  submitAnswer() {
    const userAnswers = {}
    this.categories.forEach(category => {
      const categoryItems = this.element.querySelectorAll(`.category[data-category="${category.name}"] .option`)
      userAnswers[category.name] = Array.from(categoryItems).map(item => item.dataset.option)
    })

    const result = this.checkAnswer(userAnswers)
    this.showFeedback(result)
  }

  checkAnswer(userAnswers) {
    let correctCount = 0
    let totalItems = 0

    this.categories.forEach(category => {
      const correctOptions = category.options
      const userOptions = userAnswers[category.name] || []
      totalItems += correctOptions.length

      correctOptions.forEach(option => {
        if (userOptions.includes(option)) {
          correctCount++
        }
      })
    })

    return {
      isAllCorrect: correctCount === totalItems,
      correctCount,
      totalItems
    }
  }

  showFeedback(result) {
    if (result.isAllCorrect) {
      this.feedbackTarget.textContent = "Correct! Great job!"
      this.feedbackTarget.classList.remove('text-red-500', 'opacity-0')
      this.feedbackTarget.classList.add('text-green-500', 'opacity-100')
      this.celebrate()
    } else {
      this.feedbackTarget.textContent = `Not quite right. ${result.correctCount} out of ${result.totalItems} items are in the correct position. Try again!`
      this.feedbackTarget.classList.remove('text-green-500', 'opacity-0')
      this.feedbackTarget.classList.add('text-red-500', 'opacity-100')

      // Set a timeout to fade out the feedback after 8 seconds
      setTimeout(() => {
        this.fadeFeedback()
      }, 8000)
    }
    this.feedbackTarget.classList.remove('hidden')
  }

  fadeFeedback() {
    this.feedbackTarget.classList.remove('opacity-100')
    this.feedbackTarget.classList.add('opacity-0')
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  resetGame() {
    // Clear all category containers
    this.element.querySelectorAll('.category-items').forEach(container => {
      container.innerHTML = ''
    })

    // Re-render options
    this.renderOptions()

    // Clear feedback
    this.feedbackTarget.classList.add('hidden', 'opacity-0')
    this.feedbackTarget.classList.remove('opacity-100')
    this.feedbackTarget.textContent = ''

    // Re-enable submit button if it was disabled
    this.submitButtonTarget.disabled = false

    // Re-setup drag and drop (if necessary)
    this.setupDragAndDrop()
  }

  celebrate() {
    const jsConfetti = getJsConfetti()
    jsConfetti.addConfetti()
  }
}
