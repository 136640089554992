import { Controller } from "@hotwired/stimulus"
import getJsConfetti from '../../shared/confetti'

export default class extends Controller {
  static targets = ["questionsList", "answersList", "linesContainer", "shuffleButton", "checkButton", "rotationMessage"]

  static values = { matches: Array }

  constructor(...args) {
    super(...args)
    this.boundShuffleAndReset = this.shuffleAndReset.bind(this)
    this.boundCheckWork = this.checkWork.bind(this)
    this.boundHandleButtonTouch = this.handleButtonTouch.bind(this)
  }

  connect() {
    if (this.hasMatchesValue) {
      const matches = this.matchesValue;
      this.questions = matches.map((match, index) => ({ id: index + 1, text: match.question, answer: match.answer }));
      this.shuffledAnswers = this.shuffleArray([...matches.map(match => match.answer)]);
      this.renderMatchingExercise();
    }
    this.initializeDragAndDrop();
    this.initializeTouchEvents();
    this.connections = new Map();
    this.connectedAnchors = new Set();

    this.element.addEventListener('mousedown', this.handleMouseDown.bind(this));
    this.initializeButtonEvents();

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);

    this.checkOrientation = this.checkOrientation.bind(this);
    window.addEventListener('resize', this.checkOrientation);
    this.checkOrientation();
  }

  disconnect() {
    this.removeButtonEvents()

    this.element.removeEventListener('mousedown', this.handleMouseDown.bind(this));
    window.removeEventListener('resize', this.checkOrientation);

  }

  handleResize() {
    this.checkOrientation();
    this.resetExercise();
  }

  resetExercise() {
    this.removeAllConnections();

    this.renderQuestions(this.questions);
    this.renderAnswers(this.shuffledAnswers);

    this.connections = new Map();
    this.connectedAnchors = new Set();

    this.isDragging = false;
    this.startAnchor = null;
    this.currentLine = null;

    this.linesContainerTarget.innerHTML = '';
  }

  checkOrientation() {
    const width = window.innerWidth;
    // const height = window.innerHeight;
    // const isLandscape = width > height;
    const isMobile = width < 768;

    if (isMobile) {
      this.rotationMessageTarget.classList.remove('hidden');
      this.element.classList.add('pointer-events-none');
    } else {
      this.rotationMessageTarget.classList.add('hidden');
      this.element.classList.remove('pointer-events-none');
    }
  }

  initializeButtonEvents() {
    this.shuffleButtonTarget.addEventListener('touchstart', this.boundHandleButtonTouch)
    this.checkButtonTarget.addEventListener('touchstart', this.boundHandleButtonTouch)

    if (!('ontouchstart' in window)) {
      this.shuffleButtonTarget.addEventListener('click', this.boundShuffleAndReset)
      this.checkButtonTarget.addEventListener('click', this.boundCheckWork)
    }
  }

  removeButtonEvents() {
    this.shuffleButtonTarget.removeEventListener('touchstart', this.boundHandleButtonTouch)
    this.checkButtonTarget.removeEventListener('touchstart', this.boundHandleButtonTouch)

    if (!('ontouchstart' in window)) {
      this.shuffleButtonTarget.removeEventListener('click', this.boundShuffleAndReset)
      this.checkButtonTarget.removeEventListener('click', this.boundCheckWork)
    }
  }

  handleButtonTouch(e) {
    e.preventDefault()
    if (e.target === this.shuffleButtonTarget) {
      this.shuffleAndReset()
    } else if (e.target === this.checkButtonTarget) {
      this.checkWork()
    }
  }

  renderMatchingExercise() {
    this.renderQuestions(this.questions);
    this.renderAnswers(this.shuffledAnswers);
  }

  initializeDragAndDrop() {
    this.element.addEventListener('mousedown', this.startDrag.bind(this))
    this.element.addEventListener('mousemove', this.drag.bind(this))
    this.element.addEventListener('mouseup', this.endDrag.bind(this))
    this.element.addEventListener('mouseleave', this.cancelDrag.bind(this))

    this.isDragging = false
    this.startAnchor = null
    this.currentLine = null
  }

  initializeTouchEvents() {
    this.element.addEventListener('touchstart', this.handleTouchStart.bind(this), { passive: false });
    this.element.addEventListener('touchmove', this.handleTouchMove.bind(this), { passive: false });
    this.element.addEventListener('touchend', this.handleTouchEnd.bind(this));
    this.element.addEventListener('touchcancel', this.handleTouchCancel.bind(this));

    this.scrollSpeed = 2; // Adjust this value to change scroll speed
    this.edgeThreshold = 50; // Pixels from top/bottom to trigger scroll
  }

  startDrag(e) {
    e.preventDefault();

    if (e.target.classList.contains('anchor')) {
      if (this.connectedAnchors.has(e.target)) {
        this.removeConnection(e.target);
      } else if (!this.isDragging) {
        this.isDragging = true;
        this.startAnchor = e.target;
        const rect = this.startAnchor.getBoundingClientRect();
        const containerRect = this.linesContainerTarget.getBoundingClientRect();
        this.currentLine = this.createLine(
          rect.left + rect.width / 2 - containerRect.left,
          rect.top + rect.height / 2 - containerRect.top
        );
      }
    }
  }

  drag(e) {
    if (this.isDragging && this.currentLine) {
      const containerRect = this.linesContainerTarget.getBoundingClientRect()
      this.updateLine(this.currentLine, e.clientX - containerRect.left, e.clientY - containerRect.top)
      this.updateLinePosition()
    }
  }

  endDrag(e) {
    if (this.isDragging) {
      if (e.target.classList.contains('anchor') && e.target !== this.startAnchor) {
        if (this.canConnect(this.startAnchor, e.target)) {
          this.connectAnchors(this.startAnchor, e.target)
        } else {
          this.removeLine(this.currentLine)
        }
      } else {
        this.removeLine(this.currentLine)
      }
      this.isDragging = false
      this.startAnchor = null
      this.currentLine = null
    }
  }

  cancelDrag() {
    if (this.isDragging) {
      this.removeLine(this.currentLine)
      this.isDragging = false
      this.startAnchor = null
      this.currentLine = null
    }
  }

  createLine(x1, y1) {
    const line = document.createElement('div');
    line.className = 'connection-line';
    line.style.left = `${x1}px`;
    line.style.top = `${y1}px`;
    line.style.width = '2px';
    line.style.height = '2px';
    line.style.backgroundColor = 'blue';
    line.style.transformOrigin = '0 0';
    this.linesContainerTarget.appendChild(line);
    return line;
  }

  updateLine(line, x2, y2) {
    const x1 = parseInt(line.style.left);
    const y1 = parseInt(line.style.top);
    const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const angle = Math.atan2(y2 - y1, x2 - x1);
    line.style.width = `${length}px`;
    line.style.transform = `rotate(${angle}rad)`;
  }

  removeLine(line) {
    if (line && line.parentNode) {
      line.parentNode.removeChild(line)
    } else {
      console.log("Line or parent not found")
    }
  }

  connectAnchors(start, end) {
    if (this.connections.has(start) || this.connections.has(end)) {
      this.removeConnection(start);
      this.removeConnection(end);
    }

    if (this.currentLine) {
      this.removeLine(this.currentLine);
      this.currentLine = null;
    }

    const startRect = start.getBoundingClientRect();
    const endRect = end.getBoundingClientRect();
    const containerRect = this.linesContainerTarget.getBoundingClientRect();

    const line = this.createLine(
      startRect.left + startRect.width / 2 - containerRect.left,
      startRect.top + startRect.height / 2 - containerRect.top
    );
    this.updateLine(
      line,
      endRect.left + endRect.width / 2 - containerRect.left,
      endRect.top + endRect.height / 2 - containerRect.top
    );

    this.connections.set(start, { anchor: end, line: line });
    this.connections.set(end, { anchor: start, line: line });
    this.connectedAnchors.add(start);
    this.connectedAnchors.add(end);

    start.classList.add('connected');
    end.classList.add('connected');

    this.logState();
  }

  removeConnection(anchor) {
    const connection = this.connections.get(anchor);
    if (connection) {
      this.removeLine(connection.line);
      const otherAnchor = connection.anchor;

      this.connections.delete(anchor);
      this.connections.delete(otherAnchor);
      this.connectedAnchors.delete(anchor);
      this.connectedAnchors.delete(otherAnchor);

      anchor.classList.remove('connected');
      otherAnchor.classList.remove('connected');

      this.connections.forEach((conn, key) => {
        if (conn.anchor === anchor || conn.anchor === otherAnchor) {
          this.removeConnection(key);
        }
      });

      this.logState();
    } else {
      console.log("No connection found for anchor");
    }
  }

  canConnect(start, end) {
    return (
      (start.classList.contains('question-anchor') && end.classList.contains('answer-anchor')) ||
      (start.classList.contains('answer-anchor') && end.classList.contains('question-anchor'))
    ) && !this.connectedAnchors.has(start) && !this.connectedAnchors.has(end)
  }

  logState() {
    // console.log("Current state:");
    // console.log("Connections:", this.connections);
    // console.log("Connected anchors:", this.connectedAnchors);
    // console.log("Lines container contents:", this.linesContainerTarget.innerHTML);
  }

  shuffleAndReset() {
    console.log("Shuffle and Reset clicked");

    this.removeAllConnections();
    this.shuffledAnswers = this.shuffleArray([...this.questions.map(q => q.answer)]);
    this.renderQuestions(this.questions);
    this.renderAnswers(this.shuffledAnswers);

    this.connections = new Map();
    this.connectedAnchors = new Set();
  }

  removeAllConnections() {
    this.linesContainerTarget.innerHTML = '';

    this.element.querySelectorAll('.anchor').forEach(anchor => {
      anchor.classList.remove('connected');
    });
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  renderQuestions(questions) {
    this.questionsListTarget.innerHTML = questions.map((q, index) => `
      <li class="p-2 bg-gray-100 rounded mb-2 flex items-center min-h-[60px] relative question-answer-item">
        <span class="flex items-center" data-controller="speech" data-speech-text-value="${q.text}">
          <span>${q.text}</span>
          <span data-action="click->speech#speak" data-speech-target="button" class="ml-2">${this.svg_speaker()}</span>
        </span>
        <div class="anchor question-anchor" data-index="${index}"></div>
      </li>
    `).join('');
  }

  renderAnswers(answers) {
    this.answersListTarget.innerHTML = answers.map((a, index) => `
      <li class="p-2 bg-gray-100 rounded mb-2 flex items-center min-h-[60px] justify-start relative question-answer-item">
        <div class="anchor answer-anchor" data-index="${index}" data-answer="${a}"></div>
        <span class="flex items-center ml-3" data-controller="speech" data-speech-text-value="${a}">
          <span>${a}</span>
          <span data-action="click->speech#speak" data-speech-target="button" class="ml-2">${this.svg_speaker()}</span>
        </span>
      </li>
    `).join('');
  }

  checkWork() {
    let any_incorrect_or_not_completed = false

    this.element.querySelectorAll('.question-answer-item').forEach(item => {
      item.classList.remove('correct', 'incorrect', 'not-completed');
      const existingIcon = item.querySelector('.result-icon');
      if (existingIcon) existingIcon.remove();
    });


    this.questions.forEach((question, index) => {
      const questionItem = this.questionsListTarget.children[index];
      const questionAnchor = questionItem.querySelector('.anchor');
      const connection = this.connections.get(questionAnchor);

      if (connection) {
        const connectedAnswer = connection.anchor.dataset.answer;

        if (connectedAnswer === question.answer) {
          this.markCorrect(questionItem);
          this.markCorrect(connection.anchor.closest('li'));
        } else {
          any_incorrect_or_not_completed = true
          this.markIncorrect(questionItem);
          this.markIncorrect(connection.anchor.closest('li'));
        }
      } else {
        any_incorrect_or_not_completed = true
        this.markNotCompleted(questionItem);
      }
    });

    if (!any_incorrect_or_not_completed) {
      this.celebrate()
    }

    this.answersListTarget.querySelectorAll('.anchor').forEach(anchor => {
      if (!this.connections.has(anchor)) {
        this.markNotCompleted(anchor.closest('li'));
      }
    });
  }

  markCorrect(item) {
    item.classList.add('correct');
    const icon = document.createElement('span');
    icon.className = 'result-icon correct';
    icon.textContent = '✓';
    // Insert the icon as the first child of the item
    item.insertBefore(icon, item.firstChild);
  }

  markIncorrect(item) {
    item.classList.add('incorrect');
    const icon = document.createElement('span');
    icon.className = 'result-icon incorrect';
    icon.textContent = '✗';
    // Insert the icon as the first child of the item
    item.insertBefore(icon, item.firstChild);
  }

  markNotCompleted(item) {
    item.classList.add('not-completed');
  }

  handleMouseDown(e) {
    if (e.target.classList.contains('anchor') || e.target.closest('.question-answer-item')) {
      e.preventDefault();
    }
  }

  handleTouchStart(e) {
    e.preventDefault();
    const touch = e.touches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);
    if (target.classList.contains('anchor')) {
      this.startDrag({ target, clientX: touch.clientX, clientY: touch.clientY, preventDefault: () => { } });
    }
  }

  handleTouchMove(e) {
    e.preventDefault();
    if (this.isDragging) {
      const touch = e.touches[0];
      this.drag({ clientX: touch.clientX, clientY: touch.clientY, preventDefault: () => { } });

      this.checkEdgeScroll(touch.clientY);
    }
  }

  checkEdgeScroll(touchY) {
    const viewportHeight = window.innerHeight;

    if (touchY < this.edgeThreshold) {
      window.scrollBy(0, -this.scrollSpeed);
      this.updateLinePosition();
    } else if (touchY > viewportHeight - this.edgeThreshold) {
      window.scrollBy(0, this.scrollSpeed);
      this.updateLinePosition();
    }
  }

  updateLinePosition() {
    if (this.currentLine) {
      const containerRect = this.linesContainerTarget.getBoundingClientRect();
      const startAnchorRect = this.startAnchor.getBoundingClientRect();
      const x1 = startAnchorRect.left + startAnchorRect.width / 2 - containerRect.left;
      const y1 = startAnchorRect.top + startAnchorRect.height / 2 - containerRect.top;
      this.currentLine.style.left = `${x1}px`;
      this.currentLine.style.top = `${y1}px`;
    }
  }

  handleTouchEnd(e) {
    if (this.isDragging) {
      const touch = e.changedTouches[0];
      const target = document.elementFromPoint(touch.clientX, touch.clientY);
      this.endDrag({ target, clientX: touch.clientX, clientY: touch.clientY, preventDefault: () => { } });
    }
  }

  handleTouchCancel() {
    this.cancelDrag();
  }

  svg_speaker() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" version="1.0"  width="20" height="20" viewBox="0 0 75 75">
        <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" style="stroke-width:5;stroke-linejoin:round;fill:#111;"/>
        <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" style="fill:none;stroke-width:5;stroke-linecap:round"/>
      </svg>
    `
  }

  celebrate() {
    const jsConfetti = getJsConfetti()
    jsConfetti.addConfetti()
  }
}
