import { Controller } from "@hotwired/stimulus"
import getJsConfetti from '../../shared/confetti'

export default class extends Controller {
  static targets = ["question", "rankingList", "optionsContainer", "feedback", "toggleMask", "rotationMessage"]
  static values = { rankings: Object }

  connect() {
    this.isMasked = true
    this.loadGame()
    this.initializeDragAndDrop()
    this.scrollSpeed = 5
    this.scrollThreshold = 50
    this.checkOrientation();
    this.replacementsMade = false
    this.initializeResizeListener()
    console.log('tester')
  }

  disconnect() {
    this.removeDragAndDropListeners()
    this.removeResizeListener()
  }

  removeDragAndDropListeners() {
    this.element.removeEventListener('dragstart', this.dragStartHandler)
    this.element.removeEventListener('dragover', this.dragOverHandler)
    this.element.removeEventListener('drop', this.dropHandler)

    this.element.removeEventListener('touchstart', this.touchStartHandler)
    this.element.removeEventListener('touchmove', this.touchMoveHandler)
    this.element.removeEventListener('touchend', this.touchEndHandler)
  }

  removeResizeListener() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  initializeResizeListener() {
    this.resizeHandler = this.checkOrientation.bind(this)
    window.addEventListener('resize', this.resizeHandler)
  }

  checkOrientation() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isLandscape = width > height;
    const isMobile = width < 768;

    if (isMobile && !isLandscape) {
      this.rotationMessageTarget.classList.remove('hidden');
      this.element.classList.add('pointer-events-none');
    } else {
      this.rotationMessageTarget.classList.add('hidden');
      this.element.classList.remove('pointer-events-none');
    }
  }

  loadGame() {
    const gameData = this.rankingsValue
    if (gameData.question && gameData.correct_order) {
      this.questionTarget.textContent = gameData.question
      this.correctOrder = gameData.correct_order
      this.resetGame(gameData.correct_order)
    } else {
      this.element.innerHTML = "<p class='mt-8 text-center font-bold text-xl text-red-500'>No content available for this game. Try regenerating the content.</p>"
    }
  }

  resetGame(options) {
    this.rankingListTarget.innerHTML = options.map(() =>
      `<li class="my-2 p-2 bg-gray-100 rounded droppable ranking-list-item" data-contents--rankings-target="rankingSpot"></li>`
    ).join('')

    this.shuffledOptions = this.shuffleArray([...options])
    this.displayOptions()

    if (!this.replacementsMade) {
      this.hideToggleMaskButton()
    }

    this.feedbackTarget.textContent = ''
    this.toggleMaskTarget.textContent = "Show Dates"
  }

  hideToggleMaskButton() {
    this.toggleMaskTarget.classList.add('hidden')
  }

  displayOptions() {
    const optionsToDisplay = this.shuffledOptions.map(option => ({
      masked: this.maskDateReferences(option),
      original: option
    }))

    this.optionsContainerTarget.innerHTML = optionsToDisplay.map((option, index) => {
      const text = this.isMasked ? option.masked : option.original
      return this.createDraggableElementHTML(option.original, option.masked, text)
    }).join('')
  }

  createDraggableElementHTML(originalText, maskedText, displayText) {
    return `
      <div class="bg-blue-500 text-white p-2 rounded cursor-move draggable" draggable="true"
           data-original-text="${originalText}" data-masked-text="${maskedText}"
           data-controller="speech" data-speech-text-value="${originalText}">
        <span>${displayText}</span>
        <span
          data-action="click->speech#speak"
          data-speech-target="button"
          class="ml-2 cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="currentColor" version="1.0"  width="25" height="25" viewBox="0 0 75 75">
            <path d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z" style="stroke-width:5;stroke-linejoin:round;fill:#111;"/>
            <path d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6" style="fill:none;stroke-width:5;stroke-linecap:round"/>
          </svg>
        </span>
      </div>
    `
  }

  maskDateReferences(text) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const monthRegex = new RegExp('\\b(' + months.join('|') + ')\\b', 'gi')
    const yearRegex = /\b\d{4}\b/g

    let replacementsMade = false
    let maskedText = text.replace(monthRegex, (match) => {
      replacementsMade = true
      return '____'
    }).replace(yearRegex, (match) => {
      replacementsMade = true
      return '____'
    })

    if (replacementsMade) {
      this.replacementsMade = true
    }

    return maskedText
  }

  toggleMask() {
    this.isMasked = !this.isMasked
    this.updateDisplayedText()
    this.toggleMaskTarget.textContent = this.isMasked ? "Show Dates" : "Hide Dates"
  }

  updateDisplayedText() {
    const optionDraggables = this.optionsContainerTarget.querySelectorAll('.draggable')
    this.updateDraggables(optionDraggables)

    const rankingDraggables = this.rankingListTarget.querySelectorAll('.draggable')
    this.updateDraggables(rankingDraggables)
  }

  updateDraggables(draggables) {
    draggables.forEach(draggable => {
      const textSpan = draggable.querySelector('span')
      if (textSpan) {
        if (this.isMasked) {
          textSpan.textContent = draggable.dataset.maskedText
        } else {
          textSpan.textContent = draggable.dataset.originalText
        }
      }
    })
  }

  initializeDragAndDrop() {
    this.dragStartHandler = this.dragStart.bind(this)
    this.dragOverHandler = this.dragOver.bind(this)
    this.dropHandler = this.drop.bind(this)
    this.touchStartHandler = this.touchStart.bind(this)
    this.touchMoveHandler = this.touchMove.bind(this)
    this.touchEndHandler = this.touchEnd.bind(this)

    this.element.addEventListener('dragstart', this.dragStart.bind(this))
    this.element.addEventListener('dragover', this.dragOver.bind(this))
    this.element.addEventListener('drop', this.drop.bind(this))

    this.element.addEventListener('touchstart', this.touchStart.bind(this), { passive: false })
    this.element.addEventListener('touchmove', this.touchMove.bind(this), { passive: false })
    this.element.addEventListener('touchend', this.touchEnd.bind(this))
  }

  dragStart(e) {
    if (e.target.classList.contains('draggable')) {
      e.dataTransfer.setData('text/plain', e.target.dataset.originalText)
      e.dataTransfer.setData('maskedText', e.target.dataset.maskedText)
      const sourceType = e.target.closest('.droppable') ? 'rankingList' : 'optionsContainer'
      e.dataTransfer.setData('sourceType', sourceType)
      e.dataTransfer.effectAllowed = 'move'
    }
  }

  dragOver(e) {
    e.preventDefault()
    const target = this.getDropTarget(e.target)
    if (target) {
      e.dataTransfer.dropEffect = 'move'
    }
  }

  drop(e) {
    e.preventDefault()
    const originalText = e.dataTransfer.getData('text/plain')
    const maskedText = e.dataTransfer.getData('maskedText')
    const sourceType = e.dataTransfer.getData('sourceType')
    const target = this.getDropTarget(e.target)

    if (target) {
      if (target.classList.contains('droppable')) {
        this.handleDropInRankingList(originalText, maskedText, target, sourceType)
      } else if (target === this.optionsContainerTarget) {
        this.handleDropInOptions(originalText, maskedText, sourceType)
      }
    }
    this.updateRankingOrder()
  }

  getDropTarget(element) {
    const droppable = element.closest('.droppable')
    if (droppable) return droppable

    let current = element
    while (current && current !== this.element) {
      if (current === this.optionsContainerTarget) return current
      current = current.parentElement
    }

    return null
  }

  touchStart(e) {
    const touch = e.touches[0]
    const target = document.elementFromPoint(touch.clientX, touch.clientY)
    if (target.closest('.draggable')) {
      e.preventDefault()
      this.touchDraggedElement = target.closest('.draggable')
      const rect = this.touchDraggedElement.getBoundingClientRect()
      this.touchOffsetX = touch.clientX - rect.left
      this.touchOffsetY = touch.clientY - rect.top
      this.touchDraggedElement.classList.add('dragging')
      this.initialScrollY = window.pageYOffset
      this.lastTouchY = touch.clientY
    }
  }

  touchMove(e) {
    if (this.touchDraggedElement) {
      e.preventDefault()
      const touch = e.touches[0]
      const scrollDelta = window.pageYOffset - this.initialScrollY

      this.touchDraggedElement.style.position = 'fixed'
      this.touchDraggedElement.style.left = `${touch.clientX - this.touchOffsetX}px`
      this.touchDraggedElement.style.top = `${touch.clientY - this.touchOffsetY}px`
      this.touchDraggedElement.style.zIndex = '1000'

      this.autoScroll(touch.clientY, e)
      this.lastTouchY = touch.clientY
    }
  }

  autoScroll(y, event) {
    const windowHeight = window.innerHeight
    const topThreshold = this.scrollThreshold
    const bottomThreshold = windowHeight - this.scrollThreshold

    if (y < topThreshold) {
      this.startScrolling(-this.scrollSpeed, event)
    } else if (y > bottomThreshold) {
      this.startScrolling(this.scrollSpeed, event)
    } else {
      this.stopScrolling()
    }
  }

  startScrolling(speed, event) {
    if (!this.scrollInterval) {
      this.scrollInterval = setInterval(() => {
        window.scrollBy(0, speed)
        if (this.touchDraggedElement && event && event.touches && event.touches[0]) {
          const touch = event.touches[0]
          const newScrollY = window.pageYOffset
          const scrollDelta = newScrollY - this.initialScrollY
          const touchDelta = touch.clientY - this.lastTouchY

          this.touchDraggedElement.style.top = `${parseFloat(this.touchDraggedElement.style.top) + touchDelta + speed}px`
          this.lastTouchY = touch.clientY
          this.initialScrollY = newScrollY
        }
      }, 16)
    }
  }

  stopScrolling() {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval)
      this.scrollInterval = null
    }
  }

  touchEnd(e) {
    if (this.touchDraggedElement) {
      const touch = e.changedTouches[0]
      const dropTarget = this.getDropTargetAtPoint(touch.clientX, touch.clientY)
      if (dropTarget) {
        this.handleTouchDrop(dropTarget)
      } else {
        this.resetDraggedElement()
      }
      this.stopScrolling()
      this.updateRankingOrder()
    }
  }

  getDropTargetAtPoint(x, y) {
    const elements = document.elementsFromPoint(x, y)
    return elements.find(el => {
      return el.classList.contains('droppable') || el === this.optionsContainerTarget
    })
  }

  handleTouchDrop(dropTarget) {
    const draggedElement = this.touchDraggedElement
    const originalText = draggedElement.dataset.originalText
    const maskedText = draggedElement.dataset.maskedText
    const sourceType = draggedElement.closest('.droppable') ? 'rankingList' : 'optionsContainer'

    if (dropTarget.classList.contains('droppable')) {
      this.handleDropInRankingList(originalText, maskedText, dropTarget, sourceType)
    } else if (dropTarget === this.optionsContainerTarget) {
      this.handleDropInOptions(originalText, maskedText, sourceType)
    }

    this.resetDraggedElement()
  }

  resetDraggedElement() {
    if (this.touchDraggedElement) {
      this.touchDraggedElement.style.transition = 'all 0.3s ease'
      const rect = this.touchDraggedElement.getBoundingClientRect()
      const scrollDelta = window.pageYOffset - this.initialScrollY
      this.touchDraggedElement.style.left = `${rect.left}px`
      this.touchDraggedElement.style.top = `${rect.top - scrollDelta}px`
      setTimeout(() => {
        this.touchDraggedElement.style.transition = ''
        this.touchDraggedElement.style.position = ''
        this.touchDraggedElement.style.left = ''
        this.touchDraggedElement.style.top = ''
        this.touchDraggedElement.style.zIndex = ''
        this.touchDraggedElement.classList.remove('dragging')
        this.touchDraggedElement = null
      }, 300)
    }
  }

  handleDropInRankingList(originalText, maskedText, target, sourceType) {
    let draggableElement = this.touchDraggedElement || this.findSourceElement(originalText, sourceType)

    if (target.children.length === 0) {
      target.appendChild(draggableElement)
    } else {
      const existingElement = target.firstElementChild

      if (sourceType === 'rankingList') {
        const sourceSpot = draggableElement.closest('.droppable')
        sourceSpot.appendChild(existingElement)
      } else {
        this.optionsContainerTarget.appendChild(existingElement)
      }

      target.appendChild(draggableElement)
    }

    if (sourceType === 'optionsContainer') {
      this.removeFromOptions(originalText)
    }
  }

  handleDropInOptions(originalText, maskedText, sourceType) {
    if (sourceType === 'rankingList') {
      const existingElement = this.touchDraggedElement || this.findSourceElement(originalText, sourceType)
      if (existingElement) {
        const newElement = this.createDraggableElement(originalText, maskedText)
        this.optionsContainerTarget.appendChild(newElement)

        existingElement.remove()
      }
      this.removeFromRankingList(originalText)
    }
  }

  findSourceElement(data, sourceType) {
    let element = null
    if (sourceType === 'optionsContainer') {
      element = Array.from(this.optionsContainerTarget.children).find(el => el.dataset.originalText === data)
    } else if (sourceType === 'rankingList') {
      element = Array.from(this.rankingListTarget.children)
        .find(li => li.firstElementChild && li.firstElementChild.dataset.originalText === data)?.firstElementChild
    }
    return element
  }

  removeFromOptions(data) {
    const optionElement = Array.from(this.optionsContainerTarget.children).find(el => el.dataset.originalText === data)
    if (optionElement) {
      optionElement.remove()
    }
  }

  removeFromRankingList(data) {
    const rankingSpot = Array.from(this.rankingListTarget.children).find(li => li.firstElementChild && li.firstElementChild.dataset.originalText === data)
    if (rankingSpot && rankingSpot.firstElementChild) {
      rankingSpot.firstElementChild.remove()
    }
  }

  createDraggableElement(originalText, maskedText) {
    const draggableElement = document.createElement('div')
    draggableElement.classList.add('bg-blue-500', 'text-white', 'p-2', 'rounded', 'cursor-move', 'draggable')
    draggableElement.setAttribute('draggable', 'true')
    draggableElement.dataset.originalText = originalText
    draggableElement.dataset.maskedText = maskedText

    const textSpan = document.createElement('span')
    textSpan.textContent = this.isMasked ? maskedText : originalText
    draggableElement.appendChild(textSpan)

    return draggableElement
  }

  checkOrder() {
    const currentOrder = Array.from(this.rankingListTarget.children)
      .map(li => li.firstElementChild?.dataset.originalText)
      .filter(text => text !== undefined)

    const correctCount = currentOrder.reduce((count, item, index) => {
      return count + (item === this.correctOrder[index] ? 1 : 0)
    }, 0)

    const isCorrect = JSON.stringify(currentOrder) === JSON.stringify(this.correctOrder)

    if (isCorrect) {
      this.feedbackTarget.textContent = "Correct! Great job!"
      this.feedbackTarget.classList.add('text-green-500')
      this.feedbackTarget.classList.remove('text-red-500')
      this.celebrate()
    } else {
      const totalItems = this.correctOrder.length
      this.feedbackTarget.textContent = `Not quite right. ${correctCount} out of ${totalItems} items are in the correct position. Try again!`
      this.feedbackTarget.classList.add('text-red-500')
      this.feedbackTarget.classList.remove('text-green-500')
    }
  }

  reset() {
    this.resetGame(this.correctOrder)
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  updateRankingOrder() {
    const newOrder = Array.from(this.rankingListTarget.children)
      .map(li => li.querySelector('.draggable')?.dataset.originalText)
      .filter(text => text !== undefined)
  }

  celebrate() {
    const jsConfetti = getJsConfetti()
    jsConfetti.addConfetti()
  }
}
